(function ($) {
  DGS.Resizer.getInstance().register(function () {
    //hide all forms in css and load then with JS when all info is ready

    //decide if form will fit inside intro banner or should it stack
    $(".component.intro-banner + .component.form.form-on-top").each(function () {
      $(this).removeClass("form-overflow");
      var bannerHight = $(this).prev(".component.intro-banner").outerHeight();
      var availableSpace = bannerHight - $("#header").outerHeight();
      var form = $(this).find(".scfForm");
      if (availableSpace <= form.outerHeight()) {
        $(this).addClass("form-overflow");
      } else {
        var topMargin = 0 - form.outerHeight() - 20;
        form.css({
          top: topMargin
        });
      }
    });

    //for wide form place the button inside .scfSectionContent container
    $(".component.form.align-button").each(function () {
      $button = $(this).find(".scfSubmitButtonBorder");
      $content = $(this).find(".scfSectionContent");
      $content.append($button);
    });
    $(".component.form.telNumLengthValidate .scfTelephoneTextBox").attr("minlength", "10").attr("maxlength", "10");
    $(".component.form.telNumLengthValidate .scfSubmitButton").click(function (e) {
      var $telephone = $(this).closest(".component.form.telNumLengthValidate").find(".scfTelephoneTextBox");
      $telephone.each(function () {
        if ($(this).val().length != 10 || $(this).val().match(/^\d+$/) == null) {
          e.preventDefault();
          $(this).nextAll(".scfValidator").first().show();
        } else {
          $(this).nextAll(".scfValidator").first().hide();
        }
      });
    });
    setTimeout(function () {
      $(".component.form").each(function () {
        $(this).css("opacity", 1);
      });
    }, 1000);
  });
  DGS.OnLoad.getInstance().register(function () {
    $(".scfRequired, .scfValidatorRequired").each(function () {
      var _this = $(this);
      var parent = _this.parent();
      var label = parent.find("label");
      if (_this.hasClass("scfValidatorRequired")) {
        var message = _this.attr("title");
        if (message) {
          parent.append("<span class=\"msgAlert\">".concat(message, "</span>"));
        }
      }
      label.append('<span class="fieldRequired">*</span>');
    });
    var halfGroup = [];
    $(".scfSectionContent > div").each(function (i, item) {
      if ($(item).hasClass("halfWidth")) {
        halfGroup.push(item);
      } else {
        $(halfGroup).wrapAll('<div class="halfWidth-wrapper" />');
        halfGroup.length = 0;
      }
    });
  });
  var validation = $(".scfValidationSummary");
  var wasValidated = validation.children().length;
  var scrollToForm = function scrollToForm() {
    var topOffset = $(".accordion-form").offset().top;
    var scrollTop = topOffset - ($("#header").outerHeight() + ($(".component.cookie-warning").outerHeight() || 0));
    $("body,html").animate({
      scrollTop: scrollTop
    }, 500);
  };
  if (wasValidated) {
    setTimeout(function () {
      $(".accordion-form .component-content .scfForm").show();
      scrollToForm();
    }, 500);
  }
  $(".show-form-button").on("click", function (e) {
    e.preventDefault();
    $(".accordion-form .scfForm").slideToggle(function () {
      if ($(this).css("display") === "inline") {
        scrollToForm();
      }
    });
  });
  //* Commented line below is only for dev purpose. It injected sitecore script for js fields validation, and it is available on test/dev/prod pages but not on localhost
  // $('body').append('<script src="/WebResource.axd?d=x2nkrMJGXkMELz33nwnakO-TeTjJgzPhqCBoy3_Y3UidCvvPH0AUhgSruC_UDB3aB94BLqV0NZiB-1fSvUcFoPrue7o1&amp;t=637460981481343508" type="text/javascript"></script>');

  // handling iframe forms
  var adjustHeight = function adjustHeight(iFrame, iFrameHeightMobile, iFrameHeightDesktop) {
    if (window.matchMedia('(max-width: 768px)').matches) {
      iFrame.height(iFrameHeightMobile);
    } else {
      iFrame.height(iFrameHeightDesktop);
    }
  };
  var iFrameDataElement = $("#pardotFrame");
  if (iFrameDataElement) {
    var iFrameHeightMobile = iFrameDataElement.attr("data-height-mobile");
    var iFrameHeightDesktop = iFrameDataElement.attr("data-height-desktop");
    var rawSrc = iFrameDataElement.attr("data-src");
    var windowLocation = window.location.href;
    var newSrc = rawSrc + "?Product_Source_URL=" + windowLocation + "&Product_Source_URL2=" + windowLocation;

    // TODO: Make the height responsive, user story nr 198165
    var iFrameElement = "<div class=\"accordion-form\"><iframe width=\"100%\" class=\"scfForm\" src=\"".concat(newSrc, "\"></iframe></div>");
    iFrameDataElement.replaceWith(iFrameElement);
    var formiFrame = $("iframe.scfForm");
    adjustHeight(formiFrame, iFrameHeightMobile, iFrameHeightDesktop);
    $(window).on("resize", function () {
      return adjustHeight(formiFrame, iFrameHeightMobile, iFrameHeightDesktop);
    });

    // iFrameResize({ log: true, heightCalculationMethod: "bodyScroll", targetOrigin: 'https://go.pardot.com' }, 'iframe.scfForm');
  }
})(jQuery);