require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
// require("../../../wdh-foundation/sms/sms-api.js");
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
require("@demant/legacy/custom-scroll");
require("../../../wdh-foundation/dateformat/dateformat.js");
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
require("@demant/megamenu");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("@demant/legacy/googlemaps");
require("iframe-resizer/js/iframeResizer");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("lightgallery/src/js/lightgallery.js");
require("@demant/legacy/lunametrics");
require("jquery-bridget");
require("masonry-layout");
require("moment");

// FEATURE
require("./feature/component-center-list-spot");
require("./feature/component-center-spot/component-center-spot");
require("./feature/component-color-selector-spot");
require("./feature/component-content");
require("./feature/component-cookies");
require("./feature/component-data-hierarchy");
require("./feature/component-distributor-locator");
require("./feature/component-event-calendar");
require("./feature/component-flexspot-pardot-form");
require("./feature/component-form");
require("./feature/component-iframe");
require("./feature/component-image-spot");
require("./feature/component-intro-banner");
require("./feature/component-language-selector-spot");
require("./feature/component-lattice-spot");
require("./feature/component-news-list-spot");
require("./feature/component-online-booking");
require("./feature/product-tool/component-product-tool");
require("./feature/component-redirect-notification-spot");
require("./feature/component-screening-test");
require("./feature/component-search");
require("./feature/component-spot-control-spot");
require("./feature/component-testimonial-spot");
require("./feature/component-text-image-spot");
require("./feature/component-video-spot");
require("./feature/wdh-locations-spot");
require("./feature/blog/component-blog-list");
require("./feature/blog/component-blog");
require("../../../wdh-feature/wdh-blog/src/js/wdh-categories.js");

// PROJECT
require("./project/main.js");
require("./project/custom-footer.js");
require("./project/custom-header.js");
require("./project/custom-location.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");