/**
 * Created by mady on 08-05-2017.
 */

(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    DGS.Components.DataHierarchySpot.getInstance().register('hubspot', function (data, $spot) {
      var $script = $('<div class="' + data.formID + '">');
      $script.html('<!--[if lte IE 8]><script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script><![endif]--><script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>');
      return {
        mainContainer: $('.component-content', $spot),
        sections: {
          desktop: {
            containers: [{
              className: "script-container",
              components: [$script]
            }]
          }
        }
      };
    }, function ($spot, data) {
      var executeInterval = setInterval(function () {
        try {
          hbspt.forms.create({
            portalId: data.portalID,
            formId: data.formID,
            target: ".component.hubspot .component-content ." + data.formID
          });
          clearInterval(executeInterval);
        } catch (e) {
          console.log(e);
        }
      }, 100);
    });
  });
})(jQuery, window.DGS);