/* eslint-disable no-console */
import PropTypes from 'prop-types';
import Location from './model';
import { Filter } from './LocationFilterManager';
var locationPropType = PropTypes.shape({
  Latitude: PropTypes.number,
  Longitude: PropTypes.number
});
var filters = PropTypes.arrayOf(PropTypes.instanceOf(Filter));
var formSelectOption = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string
});
var formField = PropTypes.shape({
  Name: PropTypes.string,
  Type: PropTypes.string,
  Value: PropTypes.string,
  Label: PropTypes.string,
  Placeholder: PropTypes.string,
  Required: PropTypes.bool,
  Pattern: PropTypes.string,
  Options: PropTypes.arrayOf(formSelectOption),
  ErrorMessage: PropTypes.shape({
    Required: PropTypes.string,
    Pattern: PropTypes.string
  })
});
var formSettings = PropTypes.shape({
  Fields: PropTypes.arrayOf(formField),
  FormTitle: PropTypes.string,
  SubmitLabel: PropTypes.string,
  SubmitEndpoint: PropTypes.string
});
var settings = PropTypes.shape({
  ApiKey: PropTypes.string,
  Bounds: PropTypes.shape({
    Centerpoint: locationPropType,
    NorthEast: locationPropType,
    SouthWest: locationPropType
  }),
  ClusterGridSize: PropTypes.number,
  ClusterMaxZoom: PropTypes.number,
  ListCount: PropTypes.number,
  MapConfiguration: PropTypes.string,
  Unit: PropTypes.string,
  IpLocation: locationPropType,
  ZoomLevel: PropTypes.number,
  Filters: filters,
  MinLocationsOnLoad: PropTypes.number,
  Form: formSettings
});
var defaultSettings = {
  Bounds: {
    Centerpoint: {
      Latitude: 0,
      Longitude: 0
    }
  },
  ListCount: 20
};
var func = PropTypes.func,
  string = PropTypes.string;
var defFunc = function defFunc() {
  console.log('Warning. Function not defined');
};
var locations = PropTypes.arrayOf(PropTypes.instanceOf(Location));
var defaultLocations = [];
var location = PropTypes.instanceOf(Location);
var defaultLocation = function defaultLocation() {
  return new Location();
};
var defaultSearchLocation = null;
var defaultOnSelectLocation = function defaultOnSelectLocation() {
  console.log('Warning. onSelectLocation not defined.');
};
var defaultOnOpenLocation = function defaultOnOpenLocation() {
  console.log('Warning. onOpenLocation not defined.');
};
var defaultOnCloseLocation = function defaultOnCloseLocation() {
  console.log('Warning. onCloseLocation not defined.');
};
var defaultOnSearchLocation = function defaultOnSearchLocation() {
  console.log('Warning. onSearchLocation not defined.');
};
var filter = PropTypes.instanceOf(Filter);
var defaultFilter = new Filter();
var defaultOnChangeFilter = function defaultOnChangeFilter() {
  console.log('Warning. onChangeFilter not defined.');
};
var defaultOnOpenFilterList = function defaultOnOpenFilterList() {
  console.log('Warning. onOpenFilterList not defined.');
};
var defaultOnCloseFilterList = function defaultOnCloseFilterList() {
  console.log('Warning. onCloseFilterList not defined.');
};
var components = PropTypes.arrayOf(PropTypes.element);
var defaultComponents = function defaultComponents() {
  return [];
};
var component = PropTypes.element;
var layoutType = PropTypes.oneOf(['mobile', 'desktop']);
var defaultLayoutType = 'desktop';
var openLocation = PropTypes.instanceOf(Location);
export default {
  settings: settings,
  defaultSettings: defaultSettings,
  locations: locations,
  defaultLocations: defaultLocations,
  defaultOnSelectLocation: defaultOnSelectLocation,
  defaultOnOpenLocation: defaultOnOpenLocation,
  defaultOnCloseLocation: defaultOnCloseLocation,
  location: location,
  defaultLocation: defaultLocation,
  components: components,
  defaultComponents: defaultComponents,
  component: component,
  layoutType: layoutType,
  defaultLayoutType: defaultLayoutType,
  defaultSearchLocation: defaultSearchLocation,
  defaultOnSearchLocation: defaultOnSearchLocation,
  defaultOnOpenFilterList: defaultOnOpenFilterList,
  filters: filters,
  filter: filter,
  defaultFilter: defaultFilter,
  defaultOnChangeFilter: defaultOnChangeFilter,
  defaultOnCloseFilterList: defaultOnCloseFilterList,
  func: func,
  string: string,
  defFunc: defFunc,
  openLocation: openLocation,
  formSettings: formSettings,
  formField: formField,
  formSelectOption: formSelectOption
};