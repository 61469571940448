/**
 * Created by mady on 29-03-2017.
 */
(function ($, DGS) {
  DGS.Components.DataHierarchySpot.MultiStep = DGS.Components.DataHierarchySpot.MultiStep || function () {
    var DHS = DGS.Components.DataHierarchySpot.getInstance(),
      determineStep = function determineStep() {
        var step = DGS.getQueryParameter("s", window.location.href);
        return step ? step : 0;
      };
    return {
      getInstance: function getInstance() {
        var builder = {
          _queue: [],
          _$target: null,
          _template: null,
          _queryDataCallback: null,
          _steps: [],
          _step: -1,
          _data: null,
          _stepData: null,
          _stepEndpointData: null,
          _buildConfig: null,
          _getUrlVars: function _getUrlVars() {
            var url = window.location.href,
              output = {
                params: {},
                hash: ""
              },
              hashIndex = url.indexOf('#'),
              paramIndex = url.indexOf('?'),
              rawParam,
              params,
              paramPart;
            if (hashIndex > -1) {
              output.hash = url.slice(hashIndex + 1);
            }
            if (paramIndex > -1) {
              rawParam = url.substr(paramIndex + 1, hashIndex > -1 ? hashIndex : url.length);
              params = rawParam.split('&');
              params.forEach(function (param) {
                paramPart = param.split('=');
                output.params[paramPart[0]] = paramPart[1];
              });
            }
            return output;
          },
          _nextInQueue: function _nextInQueue() {
            if (this._queue.length > 0) {
              var next = this._queue.shift();
              next.apply();
            }
          },
          _submitData: function _submitData(data, storagePath, onSuccess, onError) {
            if (storagePath) {
              $.ajax({
                url: storagePath,
                type: 'POST',
                data: JSON.stringify(data)
              }).then(function (data, textState, jqHXR) {
                onSuccess.apply(null, [data]);
              }, function (jqXHR, textState, msg) {
                onError.apply(null, [textState, msg]);
              });
            } else {
              onSuccess.apply();
            }
          },
          setTarget: function setTarget($spot) {
            this._$target = $spot;
            return builder;
          },
          setTemplate: function setTemplate(path) {
            this._template = path;
            return builder;
          },
          addStep: function addStep(buildConfigHandler) {
            this._steps.push(buildConfigHandler);
            return builder;
          },
          onBeforeRequest: function onBeforeRequest(callback) {
            this._queryDataCallback = callback;
            return builder;
          },
          run: function run(onReadyHandler) {
            var that = this;
            // load template if any
            if (that._template) {
              that._queue.push(function () {
                DHS.loadTemplate(that._$target, that._template, function () {
                  that._nextInQueue();
                });
              });
            }
            // load DHS data
            this._queue.push(function () {
              DHS.loadData(that._$target, function (data) {
                // save parsed data
                that._data = DHS.parseData(data);
                // determine step
                that._step = determineStep();
                if (that._steps.length > that._step) {
                  that._stepData = that._data.step[that._step];
                  if (that._stepData.getDataEndpoint) {
                    // has end point
                    // load endpoint if any
                    that._queue.push(function () {
                      var urlVars = that._getUrlVars(),
                        data = that._queryDataCallback ? that._queryDataCallback.apply(null, [urlVars.params, urlVars.hash]) : {},
                        requestParam = "",
                        path = that._stepData.getDataEndpoint;
                      for (var key in data) {
                        if (requestParam != "") {
                          requestParam += "&";
                        }
                        requestParam += key + "=" + encodeURIComponent(data[key]);
                      }
                      path = path.indexOf('?') == -1 ? path + '?' + requestParam : path + '&' + requestParam;
                      $.ajax({
                        url: path,
                        type: 'GET'
                      }).then(function (data) {
                        that._stepData.data = data;
                        that._nextInQueue();
                      }, function (jqXHR, textStatus, msg) {
                        that._stepData.data = null;
                        that._nextInQueue();
                      });
                    });
                  }
                  // load storage if any !!
                  if (that._stepData.getStorageEndpoint) {
                    that._queue.push(function () {
                      $.ajax({
                        url: that._stepData.getStorageEndpoint,
                        type: 'GET'
                      }).then(function (data) {
                        that._stepData.storageData = data;
                        that._nextInQueue();
                      }, function (jqXHR, textStatus, msg) {
                        that._stepData.storageData = null;
                        that._nextInQueue();
                      });
                    });
                  }

                  // get buildconfig
                  that._queue.push(function () {
                    var _buildConfig = that._steps[that._step].apply(null, [that._stepData, that._$target]);
                    if (_buildConfig) window.DGS.Builder.getInstance().build(_buildConfig);
                    that._nextInQueue();
                  });
                  if (onReadyHandler) {
                    that._queue.push(function () {
                      onReadyHandler.apply(null, [that._step, that._stepData]);
                    });
                  }
                }
                that._nextInQueue();
              });
            });
            this._nextInQueue();
          },
          addNextStepToUrl: function addNextStepToUrl(url) {
            if (url.indexOf('?') > -1) {
              url += "&";
            } else {
              url += "?";
            }
            url += "s=" + ++this._step;
            return url;
          },
          submitStorageData: function submitStorageData(data, onSuccess, onError) {
            data["step" + this._step + "Location"] = window.location.href;
            this._submitData(data, this._stepData.setStorageEndpoint, onSuccess, onError);
          },
          submitApiData: function submitApiData(data, onSuccess, onError) {
            this._submitData(data, this._stepData.setEndpointData, onSuccess, onError);
          },
          getPrevStepLocation: function getPrevStepLocation() {
            if (this._stepData.storageData) {
              var key = 'step' + (this._step - 1) + 'Location',
                prevLocation = this._stepData.storageData[key];
              if (prevLocation) {
                return prevLocation;
              }
            }
            return null;
          },
          getNextStepLocation: function getNextStepLocation() {
            var targetUrl = this._stepData.targetUrl.url;
            // success. Go to next step
            if (targetUrl) {
              return this.addNextStepToUrl(targetUrl);
            }
            return null;
          },
          nextStep: function nextStep() {
            var targetUrl = this._stepData.targetUrl.url;
            // success. Go to next step
            if (targetUrl) {
              window.location = this.addNextStepToUrl(targetUrl);
            }
          }
        };
        return builder;
      }
    };
  }();
})(jQuery, window.DGS);