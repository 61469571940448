/**
 * Get parameter from URL query(search) string
 *
 * @param {string} name
 * @returns {string}
 */
var getQueryString = function getQueryString(name) {
  var result = window.location.href.match(new RegExp("[\\?\\&]".concat(name, "=([^\\&]+)"), 'i'));
  if (result == null || result.length < 1) {
    return '';
  }
  return decodeURIComponent(result[1]);
};
export default getQueryString;