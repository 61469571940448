(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    var $navigation = $('.component.navigation'),
      mySpans = '<span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>',
      $header = $('#wrapper #header'),
      $headerSectionOne = $('.section-one', $header),
      $headerSectionTwo = $('.section-two', $header),
      shownTimeout,
      goBack = "<span class='go-back'></span>",
      labelMenu = $navigation.data('title') === "" ? "MENU" : $navigation.data('title'),
      labelLangueg = $navigation.data('languagetitle') === "" ? "LANGUAGE" : $navigation.data('languagetitle');

    ///
    /// Content overflow responsive js
    ///
    var $navExpander = $('.component.link.nav-expander');
    (function () {
      var topmenuContentOverflowCheck = function topmenuContentOverflowCheck() {
        var topmenuLogoCr,
          topmenuNavCr,
          linksCr = 0,
          $rowTwoLinks = $('.component.link:not(.nav-expander)', $headerSectionTwo);
        if ($header.hasClass('burger-switch-mq')) {
          // Media query override, while this is active
          // we should not measure anything.
          return;
        }
        topmenuLogoCr = $('.component.header-logo:not(.logo-mobile-extra)', $headerSectionTwo).outerWidth(true) || 0;
        topmenuNavCr = $navigation.outerWidth(true) || 0;
        $rowTwoLinks.each(function () {
          linksCr += $(this).outerWidth(true) || 0;
        });
        if (topmenuLogoCr + topmenuNavCr + linksCr >= $('#header > .row').width()) {
          $header.addClass('burger-switch');
          $navExpander.addClass('burger-switch');
          $navigation.addClass('burger-switch');
        } else {
          $header.removeClass('burger-switch');
          $navExpander.removeClass('burger-switch');
          $navigation.removeClass('burger-switch');
        }
      };

      /// Media-query breaks to content regardless of overflow
      var topmenuContentMediaQueryCheck = function topmenuContentMediaQueryCheck() {
        var query = window.matchMedia('(max-width: 768px)').matches;
        if (query) {
          $header.addClass('burger-switch-mq');
          $header.addClass('burger-switch');
          $navExpander.addClass('burger-switch');
          $navigation.addClass('burger-switch');
        } else {
          $header.removeClass('burger-switch-mq');
          $header.removeClass('burger-switch');
          $navExpander.removeClass('burger-switch');
          $navigation.removeClass('burger-switch');
        }
      };
      var handleTableLayout = function handleTableLayout() {
        if ($header.hasClass('burger-switch')) {
          // remove table containers
          $('li.level1 > ul', $header).each(function (index) {
            var $ul = $(this),
              $li = $('li.level2', $ul);
            if ($('.level2-table-cell', $ul).length) {
              $ul.append($li);
              $('.level2-table-cell', $ul).remove();
            }
          });
        } else {
          // add table containers if they are not there
          $('li.level1 > ul', $header).each(function () {
            var $ul = $(this),
              $li = $('li.level2', $ul),
              $tableCell = $('<div class="level2-table-cell">'),
              previousHadChildren = false;
            if (!$('.level2-table-cell', $ul).length) {
              $li.each(function () {
                var $this = $(this);
                if (previousHadChildren || $this.hasClass('new-column')) {
                  $ul.append($tableCell);
                  $tableCell = $('<div class="level2-table-cell">');
                }
                $tableCell.append($this);
                previousHadChildren = true;
                if ($('li.level3', $this).length > 0) {
                  previousHadChildren = true;
                } else {
                  $this.addClass("no-children");
                  previousHadChildren = false;
                }
              });
              $ul.append($tableCell);
            }
          });
        }
      };
      window.DGS.Resizer.getInstance().register(function () {
        topmenuContentMediaQueryCheck();
        topmenuContentOverflowCheck();
        handleTableLayout();
      });
    })();
    var clickMobileMenu = function clickMobileMenu($thisLink, $event) {
      var $thisHeight = $thisLink.innerHeight();
      if ($thisLink.siblings('ul').length > 0 && !$thisLink.hasClass('shown-level')) {
        $thisLink.siblings('ul').addClass('shown-level');
        $thisLink.siblings('ul').css('top', $thisHeight + 'px');
        $thisLink.parent().closest('ul').css('top', 0);
        if ($thisLink.parent().hasClass('level2')) $thisLink.parent().closest('ul').css('top', $thisHeight + 'px');else $thisLink.parent().closest('ul').css('top', 0);
        $thisLink.addClass('shown-level');
        $thisLink.closest('li').addClass('shown-level');
        $thisLink.parent().closest('a').removeClass('shown-level');
        $thisLink.closest('li').parent().closest('li').removeClass('shown-level');

        //hide previous level
        $thisLink.parent().parent().children('li:not(.shown-level)').addClass('hidden-level');

        //show the goback button
        $('.go-back').show();
        if ($thisLink.closest('li').hasClass('level2')) {
          $event.preventDefault();
        }
      }

      //stop any action on menu level label
      if ($thisLink.hasClass('shown-level') && $thisLink.closest('li').hasClass('level1') && $thisLink.closest('li').hasClass('shown-level')) {
        $event.preventDefault();
      }
      $event.stopPropagation();
    };
    var resizeToGrid = function resizeToGrid(domElement) {
      var w = $('body').width();
      var padding = (w - $('#header > .row').width()) / 2;
      if (!$navigation.hasClass('burger-switch')) {
        setTimeout(function () {
          domElement.css({
            'padding-left': padding + 'px',
            'padding-right': padding + 'px'
          });
        }, 0);
      } else {
        setTimeout(function () {
          domElement.css({
            'padding-left': '0',
            'padding-right': '0'
          });
        }, 0);
      }
    };
    window.DGS.Resizer.getInstance().register(function () {
      $('.component-content > ul > .isParent', $navigation).each(function () {
        if ($(this).children('ul').length > 0) {
          resizeToGrid($(this).children('ul'));
        }
      });
      if ($navigation.hasClass('burger-switch')) {
        //mobile

        //mark start level for mobile menu
        $('.component-content > ul', $navigation).addClass('shown-level');

        //add menu label
        if (!$('.navigation-mobile-label', $navigation).length) {
          $navigation.prepend($("<div class='navigation-mobile-label'>" + labelMenu + "</div>"));
        }

        //add header links to mobile menu
        if (!$('[class*="mobile-injected"]', $navigation).length) {
          $('.component.link:not([class*="header-btn"])', $headerSectionTwo).each(function () {
            $('.component-content > ul', $navigation).append($(this).find('a').clone().addClass('mobile-injected-section-two'));
          });
          $('.component.link:not(.nav-expander)', $headerSectionOne).each(function () {
            $('.component-content > ul', $navigation).append($(this).find('a').clone().addClass('mobile-injected-section-one'));
          });
          $('.mobile-injected-section-two', $navigation).each(function () {
            $(this).wrap("<li class='level1 isParent section-two'></li>");
          });
          $('.mobile-injected-section-one', $navigation).each(function () {
            $(this).wrap("<li class='level1 isParent section-one'></li>");
          });
        }

        //mark parents
        $('.component-content ul > li', $navigation).each(function () {
          var liitem = $(this);
          if (liitem.children('ul').length > 0) {
            liitem.addClass('isParent');
          }
        });
        $('.component-content > ul > li > ul > li > a:not(.btn)', $navigation).css('height', 'auto');
        var heightDiff = 0,
          buttonsHeight = 0,
          phoneHeight = 0,
          $phone = $('#header .component.phone-header'),
          buttonsAndPhoneHeight = 0;
        $('#header .component-content .component').each(function () {
          if (!($(this).hasClass('navigation') || $(this).hasClass('header-logo') || $(this).hasClass('search-field-spot') || $(this).hasClass('header-stickies') || $(this).is('[class*=section-grid]') || $(this).is('[class*=header-link]'))) {
            heightDiff += $(this).height() || 0;
            heightDiff += parseInt($(this).css('margin-top')) || 0;
            heightDiff += parseInt($(this).css('padding-top')) || 0;
            heightDiff += parseInt($(this).css('padding-bottom')) || 0;
          }
          if ($(this).is('[class*=header-btn]')) {
            buttonsHeight += $(this).height() || 0;
            buttonsHeight += parseInt($(this).css('margin-top')) || 0;
            buttonsHeight += parseInt($(this).css('padding-top')) || 0;
            buttonsHeight += parseInt($(this).css('padding-bottom')) || 0;
          }
        });
        var navHeight = $(window).height() - heightDiff;
        $('.component.navigation .component-content > ul.shown-level').height(navHeight);
        $('.component.navigation').height('100%');
        if ($phone.length) {
          //position the phone number
          $phone.css('bottom', buttonsHeight + 'px');

          //position social icons
          phoneHeight += $phone.height() || 0;
          phoneHeight += parseInt($phone.css('margin-top')) || 0;
          phoneHeight += parseInt($phone.css('padding-top')) || 0;
          phoneHeight += parseInt($phone.css('padding-bottom')) || 0;
          buttonsAndPhoneHeight = buttonsHeight + phoneHeight;
          $('#header .component[class*=social-icon]').css('bottom', buttonsAndPhoneHeight + 'px');
        }

        //adjust expanded menu height
        if ($('#header > .row > .grid-12 > div').hasClass('expanded')) $('#header > .row > .grid-12 > div').height($(window).height());

        //clone lang UL to lang-switcher
        if (!$('.lang-switcher', $navigation).length) {
          $('.language-selector-spot ul', $headerSectionOne).clone().addClass('lang-switcher').addClass('shown-level').appendTo($('.component-content', $navigation)).hide();
          $('> .component-content > ul.lang-switcher li', $navigation).each(function () {
            $(this).addClass('level1 isParent');
          });

          //add go-back span
          $('.component-content > ul.lang-switcher', $navigation).prepend(goBack);
        }
        $('.lang-switcher .go-back', $navigation).click(function () {
          //hide the lang switching menu
          $('.component-content > ul.lang-switcher', $navigation).hide();

          //show the nav menu
          $('.component-content > ul:not(.lang-switcher)', $navigation).show();

          //show the globe
          $('.language-selector-spot', $headerSectionOne).show();

          //swap menu label
          $('.navigation-mobile-label', $navigation).text(labelMenu);
        });

        // move search to burgermenu
        $(".navigation.burger-switch", $headerSectionTwo).prepend($('.search-field-spot', $headerSectionOne));
        $('.search-field-spot', $headerSectionTwo).addClass('in-burger-menu');
        if ($('#wrapper').has('.search-result-spot').length > 0) {
          $(".navigation.burger-switch", $headerSectionTwo).addClass('no-search');
        }
      } else {
        //desktop

        // move search to top menu
        $('.search-field-spot', $headerSectionTwo).insertAfter($('.nav-expander', $headerSectionOne));
        $('.search-field-spot', $headerSectionOne).removeClass('in-burger-menu');
        $(".navigation.burger-switch", $headerSectionTwo).removeClass('no-search');

        //reset back mobile menu
        $('#header > .row > .grid-12 > div').height('auto');
        $('#header .link.nav-expander.nav-expanded').trigger('click');
        $('ul', $navigation).css('top', 'auto');
        $('.go-back').hide();

        //equal height for section links
        window.DGS.EqualHeight.getInstance().register($('.component-content > ul > li > ul > li > a:not(.btn)', $navigation));
        $('.component.navigation, .component.navigation .shown-level').height('auto');

        //position the phone number
        $('#header .component.phone-header').css('bottom', 'auto');

        //position social icons
        $('#header .componnet[class*=social-icon]').css('bottom', 'auto');

        //hide the lang switching menu
        $('.component-content > ul.lang-switcher', $navigation).hide();

        //show the nav menu
        $('.component-content > ul:not(.lang-switcher)', $navigation).show();
      }
      if ($('.component-content > ul > li', $navigation).hasClass('level1')) {
        $('.component-content > ul > li', $navigation).each(function () {
          var liitem = $(this);
          if (liitem.children('ul').length > 0) {
            liitem.addClass('isParent');

            // Submenu needs adjustment due to the fact that li-s cannot be static
            resizeToGrid(liitem.children('ul'));
          }
        });
      }
      setTimeout(function () {
        $('#wrapper #content').css('padding-top', $('#wrapper #header').outerHeight());
      }, 0);
    });

    //hide desktop menu on click out
    $(document).click(function (event) {
      if (!$(event.target).closest('.component.navigation').length) {
        $('.component-content li.level1.open-menu > a', $navigation).trigger('click');
      }
    });

    //setup burger menu
    $('.nav-expander').append(mySpans);
    $('.nav-expander > .component-content').remove();
    $('.component-content > ul ', $navigation).prepend(goBack);

    //onclick behaviour
    $('ul > li > a', $navigation).click(function (e) {
      if ($navigation.hasClass('burger-switch')) {
        clickMobileMenu($(this), e);
      } else {
        //desktop  - overwrite lick click only for level1
        if ($(this).parent().hasClass('level1')) {
          if ($(this).parent().hasClass('open-menu')) {
            e.preventDefault();
            e.stopPropagation();
            $(this).parent().removeClass('open-menu');
          } else {
            if ($(this).parent().hasClass('isParent')) {
              e.preventDefault();
              e.stopPropagation();
              $('.component-content li.level1', $navigation).removeClass('open-menu');
              $(this).parent('li').addClass('open-menu');
            }
          }
        }
      }
    });

    //mobile click even on the globe
    $('.language-selector-spot', $headerSectionOne).click(function () {
      if ($header.hasClass('burger-switch')) {
        //hide real navigation UL
        $('> .component-content > ul:not(.lang-switcher)', $navigation).hide();

        //show lang-switcher
        $('.component-content > ul.lang-switcher', $navigation).show();

        //add labelLanguage text next to go back icon
        if (!$('.navigation-mobile-label', $navigation).length) {
          $navigation.prepend($("<div class='navigation-mobile-label'>" + labelLangueg + "</div>"));
        } else {
          $('.navigation-mobile-label', $navigation).text(labelLangueg);
        }

        //hide the globe
        $('.language-selector-spot', $headerSectionOne).hide();
      }
    });

    //animate on load
    if ($navigation.hasClass('burger-switch')) {
      $('#header').css("opacity", 1);
    } else {
      //Desktop
      $(window).scroll(function () {
        $('#header').addClass('scrollAlpha');
        clearTimeout($.data(this, 'scrollAlpha'));
        clearTimeout(shownTimeout);
        $.data(this, 'scrollAlpha', setTimeout(function () {
          $('#header').removeClass('scrollAlpha');

          //fold up the menu
          $('.component-content li.level1.open-menu > a', $navigation).trigger('click');
        }, 500));
        if ($(window).scrollTop() === 0) {
          $headerSectionOne.css('margin-top', 0);
          $('.component.header-logo', $headerSectionTwo).removeClass('scrolled');
          $('.component.header-logo', $headerSectionOne).removeClass('scrolled');
        } else {
          $headerSectionOne.css('margin-top', '-' + $headerSectionOne.css('height'));
          shownTimeout = setTimeout(function () {
            $('.component.header-logo', $headerSectionTwo).addClass('scrolled');
          }, 100);
          $('.component.header-logo', $headerSectionOne).addClass('scrolled');
        }
        if ($(this).scrollTop() > 0) {
          $('.open', "#header").fadeOut();
          $('.open.active', "#header").show();
        } else {
          $('.open', "#header").fadeIn();
          $('.open.active', "#header").show();
        }
        setTimeout(function () {
          $('#wrapper #content').css('padding-top', $('#wrapper #header').outerHeight());
        }, 200);
      });
    }

    // Remove title popovers
    $('a', $navigation).mouseenter(function () {
      $(this).attr('data-title', $(this).attr('title'));
      $(this).removeAttr('title');
    });
    $('a', $navigation).mouseleave(function () {
      $(this).attr('title', $(this).attr('data-title'));
      $(this).removeAttr('data-title');
    });

    //mark active and current on load
    $('.component-content > ul > li a', $navigation).each(function () {
      var href = $(this).attr('href');
      if (window.location.pathname.indexOf(href) === 0) {
        $(this).addClass('active');
      }
      if (window.location.pathname == href) {
        $(this).addClass('current');
      }
    });

    // Mobile menu back button

    $('ul:not(.lang-switcher) .go-back', $navigation).on('click', function (e) {
      e.stopPropagation();
      var $thisLi,
        $level1 = $('.component-content > ul.shown-level > li.shown-level > ul.shown-level', $navigation),
        $level2 = $('.component-content > ul.shown-level > li:not(.hidden-level) > ul.shown-level > li.shown-level', $navigation);
      if ($level1.length) {
        $thisLi = $level1.closest('li');
        $('.go-back').hide();
      } else if ($level2.length) {
        $thisLi = $level2.closest('li');
      }
      $thisLi.siblings('li').removeClass('hidden-level');
      var $thisLiH = $thisLi.children('a').innerHeight();
      setTimeout(function () {
        $thisLi.removeClass('shown-level');
      }, 1);
      $thisLi.find('ul').removeClass('shown-level');
      $thisLi.children('a').removeClass('shown-level');
      $thisLi.parent().css('top', $thisLiH);
      $thisLi.parent().closest('li').addClass('shown-level');
    });

    //open mobile menu
    $('.component.nav-expander').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('nav-expanded');
      $('body').toggleClass('no-overflow');
      $('ul, li, a', $navigation).removeClass('shown-level');
      $('.component-content > ul', $navigation).addClass('shown-level');
      var $expanded = $('.row .grid-12 > div', $header);
      $expanded.toggleClass('expanded');
      setTimeout(function () {
        $expanded.toggleClass('expanded-late');
      }, 400);
      if ($(this).hasClass('nav-expanded')) {
        $expanded.height($(window).height());
      } else {
        $expanded.height(0);
      }
      $('.hidden-level').removeClass('hidden-level');

      //make sure menu label is reverted to initial
      $('.navigation-mobile-label', $navigation).text(labelMenu);

      //show the globe
      $('.language-selector-spot', $headerSectionOne).show();
    });

    //animate in the components
    $('#header .component').each(function () {
      $(this).addClass('component-ready animate');
    });
  });
})(jQuery);