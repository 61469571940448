require("./colour-selector.js");
require("./hammer.js");
require("./jqueryhammer-full.js");
require("./knockout-310.js");
require("./model-selector.js");
import updateColourSelector from "./colour-selector";
import updateModelSelector from "./model-selector";
(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    if ($('#wrapper .product-tool-conntainer').length > 0) {
      var $content = $('#wrapper .product-tool-conntainer'),
        $top = {
          'container': '',
          'liNo': 1,
          'activeTop': ''
        },
        $slider = {
          'container': '',
          'liNo': {},
          'count': 0,
          'left': 0,
          'inView': 0,
          'devider': 0,
          'width': 0,
          'activeLeft': 0
        },
        existingComponents = {
          'top': Boolean($('.ProductToolMenu .grouping', $content).length),
          'slider': Boolean($('.ProductToolMenu .product', $content).length),
          'head': Boolean($('.ProductToolSpinningHead', $content).length),
          'description': Boolean($('.ProductToolDescription', $content).length),
          'color': Boolean($('.ProductToolColorSpot', $content).length)
        },
        oldInView = 5;
    }
    if ($content != null && Boolean($content.length)) {
      $('.ProductToolSpinningHead, .ProductToolColorSpot, .ProductToolDescription', $content).wrapAll('<div class="productInfoDiv">');
      $('.component', $content).each(function (i, e) {
        if ($(this).hasClass('ProductToolMenu')) {
          if (existingComponents.top) {
            $top.container = $('.grouping ul li', $content);
            $top.container.each(function (i, ex) {
              if ($(this).hasClass('active')) $top.liNo = i + 1;
              $(this).click(function () {
                resetActiveTop($(this));
              });
              var hep = $(this).attr('data-id');
              $slider.liNo[hep] = 1;
            });
            $top.activeTop = $('.grouping ul li:nth-child(' + $top.liNo + ')', $content).attr('data-id');
            $('.grouping ul li:nth-child(' + $top.liNo + ')', $content).addClass('active');
          }
          if (existingComponents.slider) {
            $slider.container = $('.product', e);
            var noTop = false;
            if ($top.activeTop == '') noTop = true;
            $.each($('li', $slider.container).not('li ul li'), function (index, el) {
              var tempActive = $(this).attr('data-goup-id');
              if (noTop) {
                $top.activeTop = tempActive;
                noTop = false;
              }
              if (tempActive != $top.activeTop) $(this).addClass('hide');
              if ($(this).hasClass('active')) $slider.liNo[tempActive] = index + 1;
              $(this).click(function () {
                $slider.liNo[$top.activeTop] = $(this).index() + 1;
                resetSlider();
              });
            });
            $('.scroll-right', $content).on('click', function (e) {
              var no = Number($('.ProductToolMenu .product ul', $content).css('margin-left').replace("px", ""));
              no = Math.floor(no - $slider.width);
              if (no > -Number(Math.floor($slider.width * Number($slider.count - 1)))) $('.ProductToolMenu .product ul', $content).css('margin-left', no + 'px');
            });
            $('.scroll-left', $content).on('click', function (e) {
              var no = Number($('.ProductToolMenu .product ul', $content).css('margin-left').replace("px", ""));
              no = Math.floor(no + $slider.width);
              if (no < Number(Math.floor($slider.width * Number($slider.inView - 2)))) $('.ProductToolMenu .product ul', $content).css('margin-left', no + 'px');
            });
          }
          $('ul', $slider.container).wrap('<div class="innerWrapTing">');
        }
        ;
      });
      window.DGS.Resizer.getInstance().register(function () {
        if (window.matchMedia('(max-width: 1020px)').matches) {
          $slider.inView = 3;
        } else {
          $slider.inView = oldInView;
        }
        resetSlider();
      });
      hideMenuComponent();
      hideModelSelector();
    }
    function hideMenuComponent() {
      if ($('.grouping ul li').length === 0) {
        if ($('.product > div > ul > li').length === 1) {
          $('.ProductToolMenu .product').hide();
        }
      }
    }
    function hideModelSelector() {
      $('.head_nav').hide();
    }
    function resetActiveTop($active) {
      if ($slider.inView == 0) $slider.inView = oldInView;
      $('.product', $content).css('opacity', 0);
      $top.activeTop = $($active).attr('data-id');
      $top.liNo = $($active).index() + 1;
      $('.grouping ul li', $content).each(function (i, ex) {
        $(ex).removeClass('active');
        if (i + 1 == $top.liNo) {
          $(ex).addClass('active');
        }
      });
      resetSlider();
    }
    function resetSlider() {
      $slider.count = 0;
      $.each($('li', $slider.container).not('li ul li'), function (index, el) {
        var tempActive = $(this).attr('data-goup-id');
        $(this).removeClass('hide active');
        if (tempActive != $top.activeTop) {
          $(this).addClass('hide');
        } else {
          $slider.count++;
          if (index + 1 == $slider.liNo[$top.activeTop]) {
            var tal = Math.ceil($slider.inView / 2);
            $slider.activeLeft = $slider.count - tal;
            $(this).addClass('active');
          }
        }
        var tempBTNw = 0,
          $span = $('.ProductToolMenu .grouping ul li', $content);
        $.each($('span', $span), function (index) {
          if (tempBTNw < $(this).width()) tempBTNw = $(this).width();
        });
        $.each($('span', $span), function (index) {
          $(this).css('width', tempBTNw + 'px');
        });
      });
      $slider.devider = $slider.count;
      if ($slider.devider > $slider.inView) $slider.devider = $slider.inView;
      Number(Math.floor($slider.width = $('ul', $slider.container).not('li ul').width() / $slider.devider));
      $.each($('li', $slider.container).not('li ul li'), function () {
        $(this).css('width', $slider.width + 'px');
      });
      setTimeout(function () {
        activateSlider();
      }, 250);
    }
    function activateSlider() {
      var sHeight = $($('.product ul li.active', $content)[0]).outerHeight();
      $('.ProductToolMenu .product ul', $content).css('height', sHeight + 'px');
      if ($slider.count > $slider.inView) {
        $('.scroll-left', $content).css('height', sHeight + 'px');
        $('.scroll-right', $content).css('height', sHeight + 'px');
        $('.scroll-left', $content).removeClass('hide');
        $('.scroll-right', $content).removeClass('hide');
        var no = $slider.width * $slider.activeLeft * -1;
        $('.ProductToolMenu .product ul', $content).css('margin-left', no + 'px');
      } else {
        $('.scroll-left', $content).addClass('hide');
        $('.scroll-right', $content).addClass('hide');
        $('.ProductToolMenu .product ul', $content).css('margin-left', '0px');
      }
      showProduct($('.product ul li.active', $content)[0]);
      $('.product', $content).css('opacity', 1);
    }
    function showProduct(object) {
      var productID = $('.productID', object);
      if (existingComponents.description) {
        var descriptionObject = $("#product_description");
        var input = object.getElementsByClassName("text");
        if (input.length >= 1) {
          descriptionObject[0].innerHTML = input[0].innerHTML;
        }
        descriptionObject = $("#product_logo");
        if (descriptionObject) {
          var input = object.getElementsByClassName("logo");
          if (input.length >= 1) {
            descriptionObject[0].innerHTML = "<img src='" + input[0].innerHTML + "'>";
          }
        }
        descriptionObject = $("#product_title");
        if (descriptionObject) {
          var input = object.getElementsByClassName("name");
          if (input.length >= 1) {
            descriptionObject[0].innerHTML = input[0].innerHTML;
          }
        }
      }
      if (existingComponents.color) {
        // console.log('productID : ',productID);
        var newColourSelector = dataColourSelectorTable[productID[0].innerHTML.match(/[^-|^{|^}]+/g).join('')];
        if (newColourSelector !== undefined || newColourSelector !== 'undefined') {
          updateColourSelector(newColourSelector);
        }
      }
      if (existingComponents.head) {
        // console.log('dataModelSelectorTable : ',dataModelSelectorTable);
        var newModelSelector = dataModelSelectorTable[productID[0].innerHTML.match(/[^-|^{|^}]+/g).join('')];
        if (newModelSelector !== undefined || newModelSelector !== 'undefined') {
          updateModelSelector(newModelSelector);
        }
        descriptionObject = $('#single_image_spinninghead', $content);
        var input = newModelSelector.heads[0].headImages[0];
        if (input.length >= 1) {
          descriptionObject[0].innerHTML = "<img src='" + input + "' alt='thumb'/>";
        }
        var headSpin = $(object).attr('data-dropdown').toLowerCase() == 'true';
        // console.log('headSpin : ',headSpin);
        if (headSpin) {
          descriptionObject.addClass('hide');
          $('.model-selector', $content).removeClass('hide');
        } else {
          descriptionObject.removeClass('hide');
          $('.model-selector', $content).addClass('hide');
        }
      } else if (existingComponents.color && existingComponents.description) {
        $('.ProductToolDescription', $content).css({
          'width': '45%',
          'float': 'left'
        });
      }
      if (existingComponents.description) $('#product_description ul').css('height', 'auto');
    }
  });
})(jQuery);