import { guid } from "./guid";

// literals
var literals = {
  linkType: "link",
  textType: "text",
  getPageURLTag: "getPageURL",
  foldableFormTag: "foldableForm",
  buttonAlignLeftTag: "buttonAlignLeft",
  fieldNameForUrl: "Product_Source_URL",
  fieldNameForUrl2: "Product_Source_URL2"
};
var modifyUrl = function modifyUrl(href, getPageURL, windowLocation) {
  var url = href;
  if (href.includes("http:")) url = href.replace("http:", "https:");
  if (getPageURL) {
    var urlParameters = [{
      name: literals.fieldNameForUrl,
      value: windowLocation
    }, {
      name: literals.fieldNameForUrl2,
      value: windowLocation
    }];
    var urlMap = urlParameters.map(function (_ref) {
      var name = _ref.name,
        value = _ref.value;
      return "".concat(name, "=").concat(value);
    });
    url = "".concat(url, "?").concat(urlMap.join("&"));
  }
  return url;
};
export var getParsedData = function getParsedData(theElement) {
  var windowLocation = window.location.href.split("#")[0];
  var itemsWithUid = theElement.items.map(function (item) {
    item["uid"] = guid();
    return item;
  });
  var textItems = itemsWithUid.filter(function (item) {
    return item.type === literals.textType;
  });
  var linkItem = itemsWithUid.filter(function (item) {
    return item.type === literals.linkType;
  })[0];
  var _linkItem$link = linkItem.link,
    href = _linkItem$link.href,
    content = _linkItem$link.content,
    tags = linkItem.tags;
  var getPageURL = false;
  var foldableForm = false;
  var buttonAlignLeft = false;
  if (tags.includes(literals.getPageURLTag)) {
    getPageURL = true;
  }
  if (tags.includes(literals.foldableFormTag)) {
    foldableForm = true;
  }
  if (tags.includes(literals.buttonAlignLeftTag)) {
    buttonAlignLeft = true;
  }
  var url = modifyUrl(href, getPageURL, windowLocation);
  return {
    textItems: textItems,
    url: url,
    title: content,
    foldableForm: foldableForm,
    buttonAlignLeft: buttonAlignLeft
  };
};