"use strict";

(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    function setHeight($this) {
      var $imgContainer = $('.testimonial-spot-image > img', $this),
        $textContainer = $('.testimonial-spot-text', $this),
        imgH = $imgContainer.height(),
        textH = $textContainer.height(),
        maxH = Math.max(imgH, textH),
        $textOuterContainer = $('.testimonial-spot-text-container', $this),
        pad = parseInt($textOuterContainer.css('top')) + parseInt($textOuterContainer.css('bottom'));
      if (maxH > 50) {
        $this.css('min-height', maxH + pad + 'px');
      }
    }
    $('.component.testimonial-spot ').each(function () {
      var $this = $(this);
      window.DGS.WaitForLoad.getInstance().register($this);
      window.DGS.OnContentLoaded.getInstance().register(function () {
        setHeight($('.testimonial-container', $this));
        window.DGS.LoadComplete.getInstance().register($this, function () {});
      });
    });
    window.DGS.Resizer.getInstance().register(function () {
      $('.component.testimonial-spot .testimonial-container').each(function () {
        var $this = $(this);
        setHeight($('.testimonial-container', $this));
      });
    });
  });
})(jQuery);