/* eslint-disable func-names */
(function ($, DGS) {
  var activeClass = 'active';
  DGS.OnLoad.getInstance().register(function () {
    $('.component.blog-categories').each(function () {
      // Refactor to add page locator on the corresponding blog-categories elements.
      $('.blog-category a', $(this)).each(function () {
        var href = $(this).attr('href');
        var pageUrl = window.location.pathname + window.location.search;
        if (this.href.indexOf('?') !== -1) {
          if (decodeURIComponent(pageUrl) === href) {
            $('.blog-category a').removeClass(activeClass);
            $(this).addClass('active');
          }
        } else {
          $('.blog-category a').first().addClass(activeClass);
        }
        if ($('.component.blog').data('category')) {
          var categoryName = $('.component.blog').data('category');
          var theCategory;
          var catParamValue = href.split('category=')[1];
          if (catParamValue) {
            theCategory = catParamValue.substring(href.indexOf('&'));
          }
          if (String(theCategory) && String(theCategory) === String(categoryName)) {
            $(this).addClass('active');
            $('.blog-category a').first().removeClass(activeClass);
          }
        }
      });
    });
  });
})(jQuery, window.DGS);