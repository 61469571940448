function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/* prevWrap/nextWrap */

var ko = require('./knockout-310');
var dataModelSelector;
var ModelSelector;
jQuery.fn.prevWrap = function (selector) {
  var prev = jQuery(this).prev(selector);
  if (prev.length <= 0) {
    prev = jQuery(this).parent().children(selector).last();
  }
  return prev;
};
jQuery.fn.nextWrap = function (selector) {
  var next = jQuery(this).next(selector);
  if (next.length <= 0) {
    next = jQuery(this).parent().children(selector).first();
  }
  return next;
};
var modelSelector = function modelSelector() {
  var self = this;
  self.sharedText = ko.observable(dataModelSelector.sharedText);
  self.heads = ko.observable(dataModelSelector.heads);
  self.head = ko.observable(self.heads()[0]);
  self.headToggleBox = ko.observable(false);
  self.dropNav = ko.observable(undefined);
  var clickOrTouch = "click touchstart";
  self.dataSet = function (dataSet) {
    self.sharedText(dataSet.sharedText);
    self.heads(dataSet.heads);
    self.head(self.heads()[0]);
  };
  ko.bindingHandlers.toggleBox = {
    init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
      //console.log("init toggleBox")
      jQuery(element).data("speed", 0);
    },
    update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
      //console.log("init update ")
      var val = ko.unwrap(valueAccessor());
      var el = jQuery(element);
      var speed = 400;
      var dSpeed = jQuery(element).data("speed");
      //console.log(val)

      if (dSpeed != undefined) {
        speed = dSpeed;
        jQuery(element).removeData("speed");
      }
      if (val) {
        el.stop().stop().slideDown(speed);
      } else {
        el.stop().slideUp(speed);
      }
    }
  };
  self.toggleBoxSet = function (data, e) {
    //console.log("toggle box set ");
    var status = ko.unwrap(this);
    if (status) {
      this(false);
    } else {
      this(true);
    }
  };
  self.headSelectorToggle = function () {
    //console.log("toggling heads")
    var heads = jQuery(".heads");
    heads.stop().slideToggle(400);
  };
  self.openDropNavigation = function () {
    self.dropNav(!self.dropNav());
  };
  ko.bindingHandlers.initHeadSlider = {
    update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var data = ko.utils.unwrapObservable(valueAccessor()); //compareWith, index, data
      var el = jQuery(element);
      setTimeout(function () {
        var images = el.parents(".model-selector").find(".head_image");
        el.slider({
          value: 0,
          min: 0,
          max: images.length - 1,
          step: 1,
          slide: function slide(event, ui) {
            images.css("display", "none");
            images.eq(ui.value).css("display", "block");
          },
          start: function start() {
            TweenMax.to(jQuery(".hide_on_swipe"), 0.2, {
              opacity: 0
            });
          },
          stop: function stop() {
            TweenMax.to(jQuery(".hide_on_swipe"), 0.2, {
              opacity: 1
            });
          }
        });

        //var path = api.assets.getPath(decodeURIComponent()).replace("....","..");

        jQuery(element).children().css({
          background: "initial",
          backgroundImage: "url(" + encodeURI(self.sharedText().media_slider_handler) + ")"
        });
      });
    }
  };
  self.selectHeads = function (data, e) {
    //console.log(data);
    self.head(data);
    self.dropNav(false);
  };
  self.hideOnSwipe = function (number) {
    TweenMax.to(jQuery(".hide_on_swipe"), 0.2, {
      delay: 0.2,
      opacity: number
    });
  };
  ko.bindingHandlers.initHeadRoller = {
    init: function init(element) {
      //Hammer.plugins.fakeMultitouch();
      var elem = jQuery(element);
      var slowDown = 0;
      var firstElem = elem.children().eq(0);
      firstElem.css("display", "block");
      TweenMax.to(firstElem, 0.2, {
        delay: 0.2,
        opacity: 1
      });

      //preload

      var images = [];
      elem.children().each(function (i, val) {
        var adress = jQuery(val).css("background-image").replace("url(\"", "").replace("\")", "");
        images[i] = new Image();
        images[i].src = adress;
        if (jQuery.support.opacity == false) {
          jQuery(val).css("filter", 'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=' + adress + ', sizingMethod=scale)');
        }
      });

      /*
      //scrollwheel
      elem.mousewheel(function(event) {
      event.preventDefault();
        var dY = event.deltaY;
      if (dY == -1 || dY == 1) {
          self.hideOnSwipe(0);
          var obj  =  elem.children(":visible");
          obj.css("display", "none");
          if (event.deltaY == -1) {
          obj.prevWrap().css("display", "block");
          }else{
          obj.nextWrap().css("display", "block");
          }
          //self.hideOnSwipe(0);
          //sorta buggy
          var interval = setInterval(function(){
          clearInterval(interval);
          self.hideOnSwipe(1);
          },1000);
      }
      });*/

      //drag
      setTimeout(function () {
        var intervalometer;
        elem.hammer({
          hold: false,
          swipe: false,
          prevent_default: true
        }).on("dragstart", function (e) {
          //console.log("dragstart")
          TweenMax.to(jQuery(".hide_on_swipe"), 0.2, {
            opacity: 0
          });
          e.stopPropagation();
          e.preventDefault();
          try {
            clearInterval(intervalometer);
          } catch (err) {}
        });
        elem.hammer({
          hold: false,
          swipe: false,
          dragup: false,
          dragdown: false,
          dragLockToAxis: true,
          dragBlockHorizontal: true,
          prevent_default: true
        }).on("drag", function (e) {
          //console.log("drag")
          e.stopPropagation();
          e.preventDefault();
          var gestureData = e.gesture;
          var direction = gestureData.interimDirection;
          slowDown++;
          if (slowDown > 5) {
            slowDown = 0;
            if (direction == "left" || direction == "right") {
              var obj = elem.children(":visible");
              obj.css("display", "none");
              if (direction == "left") {
                obj.nextWrap().css("display", "block");
              } else if (direction == "right") {
                obj.prevWrap().css("display", "block");
              }
              var objIndx = elem.children().index(elem.children(":visible"));
              jQuery(".head_slider").slider('value', objIndx);
            }
          }
        });
        elem.hammer({
          swipe: false,
          prevent_default: true
        }).on("dragend", function (e) {
          //console.log("dragend")
          e.stopPropagation();
          e.preventDefault();
          var gestureData = e.gesture;
          var direction = gestureData.interimDirection;
          var speed = 11 * (1 / gestureData.velocityX);
          if (speed > 0.05 && speed < 100 && speed != "Infinity" && (direction == "left" || direction == "right")) {
            var _rotatorFunction = function rotatorFunction() {
              clearInterval(intervalometer);
              var obj = elem.children(":visible");
              obj.css("display", "none");
              if (direction == "left") {
                obj.nextWrap().css("display", "block");
              } else if (direction == "right") {
                obj.prevWrap().css("display", "block");
              }
              var objIndx = elem.children().index(elem.children(":visible"));
              jQuery(".head_slider").slider('value', objIndx);
              speed *= 1.33;
              if (Math.floor(speed) <= 100) {
                intervalometer = setInterval(_rotatorFunction, speed);
              } else {
                self.hideOnSwipe(1);
              }
            };
            intervalometer = setInterval(_rotatorFunction, speed);
          } else {
            self.hideOnSwipe(1);
          }
        });
      }, 0);
    }
  };
};
var updateModelSelector = function updateModelSelector(data) {
  if (_typeof(ModelSelector) == undefined || typeof ModelSelector == 'undefined') {
    console.info("init");
    dataModelSelector = data;
    ModelSelector = new modelSelector();
    ko.applyBindings(ModelSelector, jQuery(".model-selector:last").get(0));
  } else {
    ModelSelector.dataSet(data);
  }
};
jQuery(document).ready(function () {
  if (_typeof(dataModelSelector) !== undefined && typeof dataModelSelector !== 'undefined') {
    console.info("init");
    ModelSelector = new modelSelector();
    ko.applyBindings(ModelSelector, jQuery(".model-selector:last").get(0));
  }
});
export default updateModelSelector;