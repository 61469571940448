(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    $(".cookie-warning").insertBefore('#header');
    var cookieWarning = $(".cookie-warning"),
      isEmpty = cookieWarning.find(".component-content").children().length;
    if (isEmpty) {
      cookieWarning.addClass("initialized component-ready");
    } else {
      cookieWarning.remove();
    }
    $('.cookie-warning .submit').click(function (e) {
      e.preventDefault();
      window.DGS.Cookies.getInstance().createCookie("cookie-warning", 1, 365);
      TweenMax.to($('#wrapper'), .3, {
        css: {
          marginTop: 0,
          marginBottom: 0
        },
        delay: 0
      });
      TweenMax.to($('#wrapper #header'), .3, {
        css: {
          top: 0
        },
        delay: 0
      });
      cookieWarning.remove();
      window.DGS.Event.getInstance().trigger('cookie-warning-submit');
    });
    window.DGS.Resizer.getInstance().register(function () {
      var isEmpty = cookieWarning.find(".component-content").children().length,
        newMargin = cookieWarning.outerHeight();
      if (window.matchMedia("(max-width: 1024px)").matches) {
        if (isEmpty) {
          TweenMax.to($('#wrapper'), .3, {
            css: {
              marginBottom: newMargin,
              marginTop: 0
            },
            delay: 1.5
          });
          TweenMax.to($('#wrapper #header'), .3, {
            css: {
              top: 0
            },
            delay: 1.5
          });
        } else {
          TweenMax.to($('#wrapper'), .3, {
            css: {
              marginTop: 0,
              marginBottom: 0
            },
            delay: 0
          });
          TweenMax.to($('#wrapper #header'), .3, {
            css: {
              top: 0
            },
            delay: 0
          });
        }
      } else if (isEmpty) {
        TweenMax.to($('#wrapper'), .3, {
          css: {
            marginTop: newMargin,
            marginBottom: 0
          },
          delay: 1.5
        });
        TweenMax.to($('#wrapper #header'), .3, {
          css: {
            top: newMargin
          },
          delay: 1.5
        });
      } else {
        TweenMax.to($('#wrapper'), .3, {
          css: {
            marginTop: 0,
            marginBottom: 0
          },
          delay: 0
        });
        TweenMax.to($('#wrapper #header'), .3, {
          css: {
            top: 0
          },
          delay: 0
        });
      }
    });
  });
})(jQuery);