(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    function resizeLatticeElements() {
      $('.lattice-spot').each(function () {
        var $spot = $(this);
        $(".lattice-spot-text p", $spot).css("margin-top", 0);
        window.DGS.EqualHeight.getInstance().trigger($('.lattice-spot-item', $spot), true, function (height) {
          $(".lattice-spot-text p", $spot).each(function () {
            var pHeight = (height - 40) / 2 - $(this).height();
            $(this).css("margin-top", pHeight);
          });
        });
      });
    }
    window.DGS.Resizer.getInstance().register(resizeLatticeElements);
  });
})(jQuery);