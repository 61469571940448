(function ($) {
  "use strict";

  window.DGS.Resizer.getInstance().register(function () {
    $('#footer').each(function () {
      //reset
      $('.component-content > ul > li > h4', $(this)).css({
        height: 'auto'
      });
      $('.component-content > ul > li', $(this)).css({
        height: 'auto'
      });

      //if there is no button - remove the extra bottom padding
      $('.component-content > ul > li', $(this)).each(function () {
        if (!$(this).find('[class*="btn"], [class*="handwriting"]').length) $(this).css({
          paddingBottom: '0'
        });
      });
      if (window.matchMedia('(min-width: 801px)').matches) {
        //desktop
        window.DGS.EqualHeight.getInstance().trigger($('.component-content>ul>li', $(this)), true, function () {}, true);
      } else {
        //mobile
        $('.component-content>ul>li', $(this)).css({
          height: 'auto'
        });
      }
    });
  });
})(jQuery);