(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    var btnLocator = $('.btn--locator--gmap'),
      apiKey = 'AIzaSyC24eT9tPc64_ECbkLo6vMRsEzUAASyEIg',
      //where is this from? AIzaSyDLUitCuNZK9T-RVR9cjuh9Js6Q-vcv-BU
      //web@dgs.com account: AIzaSyCiY6_CcrUGVlfC2VPTh-qlTJmEXtHJnZI
      //hjac personal for localhost :"AIzaSyCllxTypXuQI0jt4U5QJMgUAo1gDgPDDZc",

      cookieName = "postal_code",
      geocoder;
    if (btnLocator != 'undefined' && btnLocator.length > 0) {
      var resolvedApiKey = document.googleMapsApiKey || apiKey;
      $.getScript("http://maps.google.com/maps/api/js?sensor=false&key=" + resolvedApiKey, function () {
        btnLocator.click(function (event) {
          event.preventDefault();
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              getMapResults(position.coords.latitude, position.coords.longitude, event);
            }, function () {
              handleLocationError(true, event);
            });
          } else {
            // Browser doesn't support Geolocation
            handleLocationError(false, event);
          }
        });
      });
    }
    function handleLocationError(browserHasGeolocation, event) {
      if (browserHasGeolocation) {
        getAPIGeolocation(event);
      } else {
        procede(event);
      }
    }
    function getAPIGeolocation(event) {
      jQuery.post("https://www.googleapis.com/geolocation/v1/geolocate?key=" + apiKey, function (position) {
        getMapResults(position.location.lat, position.location.lng, event);
      }).fail(function (err) {
        procede(event);
      });
    }
    ;
    function getMapResults(lat, lng, event) {
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({
        'latLng': latlng
      }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          var postal_code = getPostalCodeFromResults(results);
          if (postal_code !== "") {
            window.DGS.Cookies.getInstance().createCookie(cookieName, postal_code, 1);
          }
          procede(event);
        }
      });
    }
    function getPostalCodeFromResults(results) {
      var postal_code = "";
      for (var i = 0; i < results[0].address_components.length; i++) {
        var component = results[0].address_components[i];
        if (component.types[0] == "postal_code") {
          postal_code = component.long_name;
        }
      }
      return postal_code;
    }
    function procede(event) {
      location.href = event.currentTarget.href;
    }
  });
})(jQuery);