(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    DGS.Components.DataHierarchySpot.getInstance().register("dataset", getBuildConfiguration, postBuildActions);
  });
  function getBuildConfiguration(data, $spot) {
    var groups = [];
    if (data.hasOwnProperty('Group')) {
      data['Group'].forEach(function (group) {
        var entries = [],
          groupContainer,
          $groupTitle,
          $groupSubtitle,
          $entry;
        if (group.title !== undefined && group.title !== '') {
          $groupTitle = $('<h4>' + group.title + '</h4>');
        }
        if (group.subtitle !== undefined && group.subtitle !== '') {
          $groupSubtitle = $('<span>' + group.subtitle + '</span>');
        }
        if (group.hasOwnProperty('EntryGroup')) {
          group['EntryGroup'].forEach(function (entryGroup) {
            var $entryGroup = $('<div class="entry-group' + getClasses(entryGroup) + '"></div>');
            var subentries = [];
            if (entryGroup.hasOwnProperty('Entry')) {
              entryGroup['Entry'].forEach(function (subentry) {
                buildSubEntry(subentry, subentries);
              });
            }
            $entryGroup.html(subentries);
            entries.push($entryGroup);
          });
        }
        if (group.hasOwnProperty('EntryRichText')) {
          group['EntryRichText'].forEach(function (entry) {
            buildRichTextEntry(entry, entries);
          });
        }
        if (group.hasOwnProperty('Entry')) {
          group['Entry'].forEach(function (entry) {
            buildEntry(entry, entries);
          });
        }
        groupContainer = buildContainer($groupTitle, $groupSubtitle, entries, 'group');
        groups.push(groupContainer);
      });
    }
    return {
      mainContainer: $('.component-content', $spot),
      sections: {
        desktop: {
          containers: groups
        }
      }
    };
  }
  function getClasses(entry) {
    var classes = "";
    if (entry.style !== undefined) {
      classes = " " + entry.style;
    }
    return classes;
  }
  function buildSubEntry(entry, entries) {
    if (entry.text !== undefined) {
      var $entry = $('<div class="entry' + getClasses(entry) + '">' + entry.text + '</div>');
      entries.push($entry);
    }
  }
  function buildEntry(entry, entries) {
    if (entry.text !== undefined) {
      var $entry = $('<div class="entry' + getClasses(entry) + '">' + entry.text + '</div>');
      entries.push($entry);
    }
  }
  function buildRichTextEntry(entry, entries) {
    if (entry.text !== undefined) {
      var $entry = $('<div class="entry rich-text' + getClasses(entry) + '">' + entry.text + '</div>');
      entries.push($entry);
    }
  }
  function buildContainer($groupTitle, $groupSubtitle, entries, className) {
    var groupContainer;
    if ($groupTitle !== undefined && $groupSubtitle !== undefined) {
      groupContainer = {
        className: className,
        containers: [{
          className: className + '__title',
          components: [$groupTitle, $groupSubtitle]
        }, {
          className: 'entries',
          components: entries
        }]
      };
    } else {
      groupContainer = {
        className: 'group',
        containers: [{
          className: 'entries',
          components: entries
        }]
      };
    }
    return groupContainer;
  }
  function postBuildActions($spot) {
    var $groupContainer = $('.desktop-section > .inner-container', $spot);
    $groupContainer.addClass('clearfix');
    $('.entry-group', $spot).addClass('clearfix');
    DGS.Resizer.getInstance().register(function () {
      var $tableentries = $('.data-hierarchy-spot.dataset:not(.grid-data) .component-content .entries .inner-container > .entry'),
        $entrygroups = $('.data-hierarchy-spot.dataset:not(.grid-data) .component-content .entries .inner-container > .entry-group'),
        $gridEntriesTop = $('.data-hierarchy-spot.grid-data .component-content .group:nth-child(-n+2)'),
        $gridEntriesBottom = $('.data-hierarchy-spot.grid-data .component-content .group:nth-child(n+3)');
      if (!window.matchMedia('(max-width: 991px)').matches) {
        DGS.EqualHeight.getInstance().trigger($tableentries, true);
        DGS.EqualHeight.getInstance().trigger($entrygroups, true);
        DGS.EqualHeight.getInstance().trigger($gridEntriesTop, true);
        DGS.EqualHeight.getInstance().trigger($gridEntriesBottom, true);
      } else {
        $gridEntriesTop.css("height", "auto");
        $gridEntriesBottom.css("height", "auto");
        $tableentries.css("height", "auto");
      }
    });
  }
})(jQuery, window.DGS);