(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $(".component.blog-list").each(function () {
      $("#blog-list li", $(this)).each(function () {
        // Now change the date format
        var $newsDate = $(".news-date", $(this));

        // Moves the date to after the image
        $newsDate.insertAfter($(".news-image", $(this)), null);
      });
    });
    var articles = $(".blog-list ul#blog-list li");
    var blogList = $(".component-content.bloglist");
    $(".component.blog-list").addClass("pagination-ready");
    var url = new URL(window.location.href);
    var currentPage = Number(url.searchParams.get("page")) || 1;
    var maxArticlesPerPage = 12;
    var setUrlHistory = function setUrlHistory() {
      url.searchParams.has("page") ? url.searchParams.set("page", currentPage) : url.searchParams.append("page", currentPage);
      window.history.replaceState("bloglist-page", "", url.search);
    };
    var paginateArticles = function paginateArticles() {
      if (articles.length > maxArticlesPerPage) {
        articles.addClass("hide-from-list");
        for (var i = 0; i < maxArticlesPerPage * currentPage; i++) {
          $(articles[i]).removeClass("hide-from-list");
        }
      }
      if (maxArticlesPerPage * currentPage >= articles.length) {
        $(".addMoreButton").remove();
      }
    };
    var viewMoreButton = $('<button class="addMoreButton" type="button">Load more</button>');
    blogList.append(viewMoreButton);
    viewMoreButton.on("click", function (e) {
      e.preventDefault();
      currentPage += 1;
      setUrlHistory();
      paginateArticles();
    });
    paginateArticles();
  });
})(jQuery, window.DGS);