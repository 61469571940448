(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    try {
      $('iframe').iFrameResize();
    } catch (error) {
      // Fail silently!
    }
  });
  window.DGS.Event.getInstance().on('GDPR_IFRAME_ADDED', function () {
    $('iframe').iFrameResize();
  });
})(jQuery);